<template>
  <div class="add-device-data">
      <!-- 新增图片 -->
      <div class="add-img" v-if="ruleForm.type == '图片'">
        <el-form
          label-width="90px"
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 文件类型 start -->
          <el-form-item class="type-box" label="文件类型:" required prop="type">
            <div class="item" @click="changeType('图片')">
              <img
                v-if="ruleForm.type == '图片'"
                src="@/assets/images/addselect.png"
                alt=""
              />
              <img
                src="@/assets/images/addnoselect.png"
                v-if="ruleForm.type != '图片'"
                alt=""
              />
              <span>图片</span>
            </div>
            <div class="item item2" @click="changeType('文件')">
              <img
                v-if="ruleForm.type == '文件'"
                src="@/assets/images/addselect.png"
                alt=""
              />
              <img
                src="@/assets/images/addnoselect.png"
                v-if="ruleForm.type != '文件'"
                alt=""
              />
              <span>文件</span>
            </div>
          </el-form-item>
          <!-- 文件类型:结束 -->
          <!-- 文件名称 -->
          <el-form-item label="文件名称:" required prop="fileName">
            <el-input
              type="text"
              maxlength="20"
              v-model="ruleForm.fileName"
              placeholder="请输入文件名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 文件名称结束 -->
          <!-- 上传图片 -->
          <el-form-item label="上传图片:">
            <!-- <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="ruleForm.dialogImageUrl" alt="" />
            </el-dialog> -->

            <el-upload
              :action="objData.host"
              :data="objData"
              multiple
              :limit="3"
              list-type="picture-card"
              :before-upload="beforeUploadFun"
              :on-progress="progressFun"
              :on-remove="handleRemove"
              :on-success="videoSuccess"
              :on-change="handleChange"
              :file-list="fileList1"
            >
              <!-- <i class="el-icon-plus" v-if="showAddSource"></i> -->
              <div slot="trigger" class="add-upload-box" v-if="showAddSource">
                <i class="el-icon-plus"></i>
              </div>
              <!-- <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  > -->
            </el-upload>
            <!-- <el-dialog
              :visible.sync="videodialogVisible"
              :append-to-body="true"
            >
              <video
                id="upvideo"
                width="100%"
                autoplay
                height="500"
                :src="dialogImageUrl"
                controls
              ></video>
            </el-dialog> -->
          </el-form-item>
          <!-- 上传图片结束 -->
          <!-- 提交开始 -->
          <el-form-item>
            <el-button class="btn cf f16" @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button @click="$router.go(-1)">取消</el-button>
          </el-form-item>
          <!-- 提交结束 -->
        </el-form>
      </div>
      <!-- 新增文件 -->
      <div class="add-file" v-if="ruleForm.type == '文件'">
        <el-form
          label-width="90px"
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleFormFile"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 文件类型 start -->
          <el-form-item label="文件类型:" required prop="type">
            <div class="item" @click="changeType('图片')">
              <img
                v-if="ruleForm.type == '图片'"
                src="@/assets/images/addselect.png"
                alt=""
              />
              <img
                src="@/assets/images/addnoselect.png"
                v-if="ruleForm.type != '图片'"
                alt=""
              />
              <span>图片</span>
            </div>
            <div class="item item2" @click="changeType('文件')">
              <img
                v-if="ruleForm.type == '文件'"
                src="@/assets/images/addselect.png"
                alt=""
              />
              <img
                src="@/assets/images/addnoselect.png"
                v-if="ruleForm.type != '文件'"
                alt=""
              />
              <span>文件</span>
            </div>
          </el-form-item>
          <!-- 文件类型:结束 -->
          <!-- 上传文件 start -->
          <el-form-item class="file">
            <p class="upload-text c6 f14">上传文件：</p>
            <!-- 表格 -->
            <el-table
              :header-cell-style="{ background: '#F4F4F4', height: '56px' }"
              :data="tableData"
              style="width: 620px"
              header-cell-class-name="table-header"
            >
              <el-table-column prop="name" label="文件名称" width="232">
              </el-table-column>
              <el-table-column prop="date" label="上传时间" width="219">
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="操作"
                width="167"
              >
                <template slot-scope="scope">
                  <!-- 报修 -->
                  <span
                    @click="handleRepair(scope.$index, scope.row)"
                    class="f14 see p b"
                  >
                    <router-link :to="{ name: 'repairform' }">报修</router-link>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <!-- 表格 结束 -->
            <div class="upload-box">
              <p class="line1 f14 p">
                <!-- <el-upload
                  :show-file-list="false"
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleSuccess"
                >
                  <div slot="default">
                    <img src="@/assets/images/home/upload.png" alt="" />
                    <span>上传文件</span>
                  </div>
                </el-upload> -->
                <el-upload
                  :action="objData.host"
                  :data="objData"
                  multiple
                  :limit="3"
                  :before-upload="beforeUploadFun"
                  :on-progress="progressFun"
                  :on-remove="handleRemove"
                  :on-success="videoSuccess"
                  :on-change="handleChange"
                  :file-list="fileList2"
                >
                  <img
                    v-if="showAddSource"
                    src="@/assets/images/home/upload.png"
                    alt=""
                  />
                  <span v-if="showAddSource">上传文件</span>
                  <!-- <i class="el-icon-plus" v-if="showAddSource"></i>
                  <div slot="trigger" class="add-box" v-if="showAddSource">
                    <i class="el-icon-plus"></i>
                  </div> -->
                </el-upload>
              </p>
              <p class="line2 f12 cr">注：上传文件大小&lt;200m。</p>
            </div>
          </el-form-item>
          <!-- 上传文件 end -->
          <!-- 提交开始 -->
          <el-form-item>
            <el-button class="btn cf f16" @click="submitForm('ruleFormFile')"
              >提交</el-button
            >
            <el-button @click="$router.go(-1)">取消</el-button>
          </el-form-item>
          <!-- 提交结束 -->
        </el-form>
      </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
let flag = true;
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangAddDeviceData",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateFileName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入文件名称"));
      }
      callback();
    };
    return {
      dialogVisible: false,
      ruleForm: {
        type: "图片",
        fileName: "", //文件名称
        dialogImageUrl: "",
      },
      rules: {
        fileName: [{ validator: validateFileName, trigger: "blur" }],
      },
      tableData: [], //表格数据
      id: "", //设备id

      // 图片上传

      fileList1: [],

      fileList2: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      upFile: [], //上传的文件

      upFileName: [], //上传文件名字

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },

  mounted() {},

  created() {
    this.id = this.$route.query.id;
  },

  methods: {
    // 图片上传前函数
    beforeUploadFun(file) {
      var fileType = file.type.split("/")[0];
      if (fileType == "video") {
        this.$message.info("请注意不能选择视频");
        this.fileList2 = [];
        return;
      }
      // console.log("上传前函数", file);
      // 如果是
      // 将文件名存入文件列表中
      var urlArr1 = file.name.split(".");
      // console.log("1111111111111111");
      // console.log(urlArr, "urlArr");
      let houzhui1 = urlArr1[urlArr1.length - 1];
      var filelist1 = ["docx", "pdf", "xlsx", 'zip', 'rar'];
      let resultFile1 = filelist1.some(function (item) {
        return item == houzhui1;
      });
      console.log(file);
      if (resultFile1) {
        this.upFileName.push(file.name);
        // 将文件信息存入表格中
        let date = new Date();
        let curDate = `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        console.log(file, "ppppppppppppppppppppppppppppppppppp");
        this.tableData.push({
          name: file.name,
          date: curDate,
        });
      }
      // console.log(this.upFileName, "upfilename00000");

      // console.log(this.upFileName, "上传文件名");
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      // if (this.upImg.length) {
      //   // 用户已经不是第一次上传了  判断一定要是图片才可以
      //   // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
      //   var fileType = file.type.split("/")[0];
      //   // let res = imglist.some((item) => {
      //   //   return item == fileType;
      //   // });
      //   // console.log(res, "结果");
      //   if (fileType != "image") {
      //     // 上传的不是图片
      //     // console.log("结果是图片");
      //     this.$message.info("请继续上传图片");
      //     this.fileList1.pop();
      //     return false;
      //   }
      // }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            // console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            // console.log("1111111111111111");
            // console.log(urlArr, "urlArr");
            let houzhui = urlArr[urlArr.length - 1];
            // console.log(houzhui, "houzhui");
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });

            // 文件格式
            var filelist = ["docx", "pdf", "xlsx",'zip','rar'];
            let resultFile = filelist.some(function (item) {
              return item == houzhui;
            });

            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
              if (this.type == 2) {
                this.$message({
                  message: "请选择文件",
                  icon: "none",
                });
              }

              this.fileList2.pop();
              this.showAddSource = true;
              this.upVideo = [];
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
              if (this.type == 2) {
                this.$message({
                  message: "请选择文件",
                  icon: "none",
                });
              }
              this.fileList2.pop();
              this.showAddSource = true;
              // this.upImg = [];
            }

            // 上传的是文件的处理
            if (resultFile) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upFile.push(videaUrl);
            }

            console.log(this.upImg, "img00000");
            console.log(this.upFile, "upfile-----");

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }

            if (this.upFile.length == 3) {
              this.showAddSource = false;
            }
            // console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    progressFun() {
      // console.log("正在上传");
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
      // console.log(file.url, "fileurl");
      // console.log(this.fileList, "filelist");
      this.fileList1.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          console.log(index);
          this.fileList.splice(index, 1);
          this.upFile = this.upFile.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.upFileName = this.upFileName.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.tableData = this.tableData.filter((item, delIndex) => {
            return delIndex != index;
          });
        }
      });
      this.tableData.forEach((item, index) => {
        if (item.name == file.name) {
          this.tableData.splice(index, 1);
        }
      });
      this.fileList2.forEach((item, index) => {
        // console.log(item.url);
        this.fileList.splice(index, 1);
        if (item.url == file.url) {
          console.log(index);
          this.upFile = this.upFile.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.upFileName = this.upFileName.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.tableData = this.tableData.filter((item, delIndex) => {
            return delIndex != index;
          });
        }
      });
      // console.log(this.upFile, "删除了upFile");
      // console.log(this.upFileName, "删除了upFileName");
      this.showAddSource = true;
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 图片上传方法
    videoSuccess(res, file, fileList) {
      // this.findvideocover(file.url, file);
    },

    handleSuccess(file, fileList) {
      this.tableData.push({
        name: fileList.name,
        date: fileList.uid,
      });
    },
    //删除图片
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    //上传图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      // console.log(file);
    },
    // 改变类型
    changeType(type) {
      this.ruleForm.type = type;
    },
    // 返回
    toDevice() {
      this.$router.back();
    },
    // 提交
    submitForm(formName) {
      if (!flag) {
        return;
      }
      // 文件上传参数
      // console.log(this.upFile, "upfile============");

      // console.log(filelistStr, "filelistStr");
      // console.log(filelistNameStr, "filelistNameStr");
      // console.log(this.tableData, "tabledata");
      // return;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;
          let fileType;
          fileType = this.ruleForm.type == "图片" ? 1 : 2;
          let fileName;
          let filelistStr;
          let filelistNameStr;
          let paramData;
          // filelistStr = this.upFile.map((item) => item).join(",");
          // filelistNameStr = this.upFileName.map((item) => item).join(",");
          if (fileType == 1) {
            // 图片
            fileName = this.ruleForm.fileName;
            console.log(this.upImg,'上传图片')
            filelistStr = this.upImg.map((item) => item).join(",");
            filelistNameStr = this.upImg.map((item) => item).join(",");
            paramData = {
              deviceId: this.id, //设备id
              fileType, //文件类型1  图片     2  文件
              fileName: fileName,
              fileUrl: filelistStr,
            };
          } else {
            // 文件
            filelistNameStr = this.upFileName.map((item) => item).join(",");
            fileName = filelistNameStr;
            filelistStr = this.upFile.map((item) => item).join(",");
            paramData = {
              deviceId: this.id, //设备id
              fileType, //文件类型1  图片     2  文件
              fileName: fileName,
              fileUrl: filelistStr,
            };
          }
          // console.log(paramData,'参数')
          // return
          this.$API
            .addDeviceFile(paramData)
            .then((res) => {
              if (res.code == 1) {
                setTimeout(() => {
                  flag = true;
                  this.$router.back();
                }, 800);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.add-device-data {
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    border: none !important;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .el-table .el-table__header .el-table__cell {
    padding: 0px !important;
  }
  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   新增图片
  .add-img {
    margin-top: 20px;
    margin-left: 40px;

    .type-box{
        width: 800px !important;
        // height: 80px !important;
    }

    // 性別
    .item {
      width: 100px;
      // height: 80px;
      display: inline-block;
      //   margin-left: 20px;
      // display: flex;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      // margin-left: 56px;
    }
    .el-input__inner {
      width: 240px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      background: linear-gradient(98deg, #ed1b23 0%, #f15f64 100%);
      color: #ffffff;
      font-size: 14px;
      // margin-left: 122px;
    }
    .el-upload--picture-card {
      width: 83px !important;
      height: 83px !important;
      line-height: 83px !important;
    }
    .el-upload-list__item {
      width: 83px !important;
      height: 83px !important;
    }
    .el-upload-list__item-actions {
      width: 83px !important;
      height: 83px !important;
    }
    .el-upload-list__item-thumbnail {
      width: 83px !important;
      height: 83px !important;
    }
    .is-success {
      // width: 83px !important;
      // height: 83px !important;
    }
  }
  //  新增文件
  .file .el-form-item__content {
    margin-left: 0px !important;
  }
  .add-file {
    .upload-text {
      // margin-top: 32px;
      margin-bottom: 20px;
      display: inline-block;
      margin-right: 20px;
    }
    // 上传文件
    .upload-box {
      img {
        width: 20px;
        height: 16px;
      }
    }
    margin-top: 20px;
    margin-left: 40px;
    // 性別
    .item {
      display: inline-block;
      //   margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .el-input__inner {
      width: 240px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      background: linear-gradient(98deg, #ed1b23 0%, #f15f64 100%);
      color: #ffffff;
      font-size: 14px;
    }
    .el-upload--picture-card {
      width: 83px !important;
      height: 83px !important;
      line-height: 83px !important;
    }
    .el-upload-list__item {
      width: 83px !important;
      height: 83px !important;
    }
    .el-upload-list__item-actions {
      width: 83px !important;
      height: 83px !important;
    }
    .el-upload-list__item-thumbnail {
      width: 83px !important;
      height: 83px !important;
    }
  }
}
</style>